:root {
    --header: #cfecea;
    --session1: #49b3ab;
    --session2: #eec146;
    --session3: #e84d4d;
    --session4: #9fc02d;
    --locked: #b5b5b5;
    --contentbox: #e9fbac;
    --text1: #803178;
    --parent: #c26fb9;
    --child: #81ddd7;
    --button-primary: #F58E35;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Open Sans', sans-serif;
}

html,
body {
    margin: 0;
    width: 100%;
    height: 100vh;
}

a {
    text-decoration: none;
}

body {
    background: url(../assets/images/bg-05.png) center -100px;
}

.clear {
    clear: both;
}

.clear-spacer {
    clear: both;
    height: 10px;
}

.wrapper {
    width: 100%;
    padding-bottom: 25px;
}

.wrapper-2 {
    width: 100%;
    padding-bottom: 25px;
}

.header, .header-2 {
    width: 100%;
    height: 94px;
    margin-bottom: 85px;
    background-color: var(--header);
    margin-top: 15px;
    padding-top: 20px;
}

.header-2 {
    margin-bottom: 110px;
    position: relative;
}

.header-content {
    width: 860px;
    margin: 0 auto;
    text-align: center;
}

.logo {
    width: 360px;
    margin: -33px auto 0;
}

/* Footer */
footer {
    width: 100%;
    height: 64px;
    background-color: var(--header);
    /*text-align: center;*/
    /*position: absolute;*/
    /*bottom: 0;*/
    /*left: 0;*/
}

.footer-container {
    width: 860px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Text */
h1 {
    font-family: 'Peralta', serif;
    /*font-size: 42px;*/
    font-size: clamp(36px, 5vw, 42px);
    color: var(--text1);
    margin-top: 12px;
}

h6 {
    font-family: 'Peralta', serif;
    font-size: 42px;
    margin-top: 83px;
}

h6.session-1 {
    color: var(--session1);
}

h6.session-2 {
    color: var(--session2);
}

h6.session-3 {
    color: var(--session3);
}

h6.session-4 {
    color: var(--session4);
}

h6.session-locked {
    color: var(--locked);
}

h6.login {
    color: #fff;
    margin: 0;
    padding: 5px;
    text-align: center;
}

p.question {
    font-family: 'Gabarito', sans-serif;
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 20px;
}

p.question.sub {
    font-size: 24px;
    margin-bottom: 10px;
}

p.question.mt-10 {
    margin-top: 20px;
}

.content-inner-login {
    width: 660px;
    padding: 60px 80px 35px;
    margin: 0 auto;
    background-color: #fff;
}

.content-inner-thumb {
    width: 660px;
    text-align: center;
    padding: 0px 80px 35px;
    margin: 0 auto;
    background-color: none;
}

.login-wrapper {
    width: 330px;
    float: left;
}

.login-parent, .login-child {
    width: 326px;
    margin: 0 2px 30px;
    /*float: left;*/
    border-radius: 15px 0 0 15px;
    background-color: var(--parent);
}

.login-child {
    background-color: var(--child);
    border-radius: 0 15px 15px 0;
}

.login-answer {
    width: 290px;
    height: 54px;
    margin: 0 20px 10px;
    border-radius: 7px;
    border: solid 2px var(--header);
    text-align: left;
    background-color: #fff;
}

#userName-login:focus,
#childUserName:focus {
    outline: none;
}

.login-answer.password {
   display: inline-flex;
}

.login-answer.password input:focus {
    outline: none;
}

.login-answer.password button {
    background: none;
    border: none;
}

.login-answer.password button svg {
    width: 18px;
    height: 18px;
}

.login-answer.password button:hover {
    cursor: pointer;
    color: var(--session1);
}

/*Session Dashboard*/
.session-boxes {
    width: 1180px;
    margin: 0 auto;
    text-align: center;
}

.session-single {
    width: 540px;
    margin: 0 20px 40px;
    height: 280px;
    float: left;
    border-radius: 25px;
    border: 5px solid;
    background-color: #fff;
}

.session-1-border {
    border-color: var(--session1);
}

.session-2-border {
    border-color: var(--session2);
}

.session-3-border {
    border-color: var(--session3);
}

.session-4-border {
    border-color: var(--session4);
}

.session-locked-border {
    border-color: var(--locked);
}

.session-top {
    width: 100%;
    height: 215px;
}

.session-left-1-3 {
    width: 245px;
    height: 240px;
    float: left;
    margin-top: -26px;
    display: block;
}

.session-right-1-3 {
    width: 295px;
    float: left;
}

.session-right-2-4 {
    width: 245px;
    height: 240px;
    float: left;
    margin-top: -26px;
    display: block;
}

.session-left-2-4 {
    width: 295px;
    float: left;
}

.session-bottom {
    width: calc(100% - 20px);
    height: 65px;
    border-radius: 0 0 15px 15px;
    padding-left: 21px;
    text-align: left;
}

.session-bottom.right {
    text-align: right;
    padding-left: 0;
    padding-right: 21px
}

span.start {
    line-height: 2em;
    letter-spacing: .05em;
    font-family: 'Peralta', serif;
    font-size: 36px;
    text-decoration: none;
    color: #fff;
}

span.locked {
    line-height: 2em;
    letter-spacing: .05em;
    font-family: 'Peralta', serif;
    font-size: 36px;
    text-decoration: none;
    color: #fff;
}

span.locked {
    color: #dadada;
}

span.locked:hover {
    cursor: default;
}

img.img-locked {
    filter: grayscale(80%);
}


.session-1-bg {
    background-color: var(--session1);
}

.session-2-bg {
    background-color: var(--session2);
}

.session-3-bg {
    background-color: var(--session3);
}

.session-4-bg {
    background-color: var(--session4);
}

.session-locked-bg {
    background-color: var(--locked);
}

/* Template Layout*/
.content-outer {
    width: 860px;
    margin: 0 auto 40px;
    border-radius: 25px;
    background-color: var(--contentbox);
    padding-bottom: 40px;
}

#progress .content-outer {
    width: 860px;
    margin: 0 auto 50px;
    border-radius: 25px;
    background-color: var(--contentbox);
    padding-bottom: 40px;
}

.content-inner {
    width: 660px;
    padding: 35px 80px;
    margin: 0 auto;
    background-color: #fff;
}

.content-avatar {
    width: 180px;
    height: 170px;
    margin-top: -100px;
    margin-left: 20px;
    float: left;
}

.content-title {
    width: 575px;
    margin-left: 25px;
    float: left;
}

.answer {
    width: calc(100% - 4px);
    height: 54px;
    margin-bottom: 5px;
    border-radius: 7px;
    border: solid 2px var(--session1);
    text-align: left;
    background-color: #fff;
}

.button,
.button2 {
    width: 100%;
    margin: 25px auto 0;
    text-align: center;
}

.button2 {
    margin-top: 0;
}

/* Login Container*/
.login-container {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
}

/* Added Styles */
/* Button */
.btn-primary {
    border: none;
    border-radius: 12px;
    background-color: var(--button-primary);
    color: #fff;
    font-size: 30px;
    line-height: 1.3em;
    padding: 5px 30px;
}

.btn-primary:hover {
    cursor: pointer;
    background-color: #e78431;
}

.btn-primary:focus {
    outline: none;
}

.btn-primary:disabled {
    background-color: var(--locked);
}

.btn-primary:disabled:hover {
    cursor: default;
}

.btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

#progress.btn-container {
    margin: 0 0 30px;
}

.btn-primary.logout {
    width: 100%;
    max-width: 244px;
}

/* Loader */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 207px);
}

.loading-container.intro-form-modal {
    width: 1010px;
}

.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Login Form */
.login-answer input {
    width: 278px;
    height: 52px;
    border-radius: 7px;
    border: none;
    background: transparent;
    font-size: 18px;
}

/* Removal of browser autofill background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.login-answer input {
    padding-left: 10px;
}

button.button {
    padding: 0;
}

/* Parent & Child Forms */
.form-input-container {
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    outline: #49b3ab solid 1px;
    gap: 20px;
}

.answer.checkbox-input {
    overflow: hidden;
}

.checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: #49b3ab solid 1px;
    background: transparent;
    cursor: pointer;
}

.form-input-container.checked {
    background: rgba(90, 217, 208, 0.53);
}

.checkbox-button-container {
    width: 54px;
    min-width: 54px;
    height: 100%;
    outline: #49b3ab solid 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.form-input-container label {
    font-size: 18px;
    line-height: 1.3em;
    padding: 0 10px 0 0;
}

p.goal-setting-label,
p.tips-label,
p.action-label,
p.fpp-label,
p.bonus-points-label,
p.plan-goal-label,
p.goal-reporting,
p.super-power-label,
p.not-found {
    font-size: 20px;
    line-height: 1.3em;
    margin-bottom: 10px;
}

p.plan-goal-label.bold {
    font-weight: 600;
}

p.super-power-label {
    margin-bottom: 0;
}

p.fpp-label.title {
    font-family: 'Gabarito', sans-serif;
    /*font-weight: 400;*/
    text-align: center;
    font-size: 22px;
    line-height: 1.3em;
}

p.action-label.bold {
    font-weight: 600;
}

.accent-text {
    color: var(--session1);
    font-weight: 600;
}

.list {
    padding-left: 20px;
    margin-bottom: 10px
}

.list.icons {
    margin-bottom: 0;
}

.list.icons .tips-text {
    margin-bottom: 0;
}

.list.icons li:last-child {
    margin-bottom: 15px;
}

.list li {
    font-size: 18px;
    line-height: 1.3em;
    margin-bottom: 15px;
}

.list li:last-child {
    margin-bottom: 0;
}

.list.plan-goal li {
    margin-bottom: 5px;
}

.list.plan-goal {
    margin-left: 1.5rem;
}

.list.goal-reporting li {
    list-style: none;
    margin-left: 24px;
    margin-top: 1rem;
}

.tips-row {
    width: 100%;
    display: inline-flex;
    gap: 15px;
}

.tips-row p.tips-text {
    margin-bottom: 0;
}

p.tips-text {
    margin-bottom: 10px;
}

.tips-icon {
    color: var(--session2);
    font-size: 18px;
}

p.info-text {
    font-size: 18px;
    line-height: 1.3em;
    margin-bottom: 10px;
}

.email-link {
    color: var(--text1);
}

p.text-sm {
    font-size: 15px;
    line-height: 1.3em;
    margin-bottom: 10px;

}

.info-text-container {
    width: 100%;
    text-align: center;
}

.email-container {
    width: 100%;
    /*text-align: center;*/
    padding-top: 10px;
}

/* Form Error Container  */
.form-error-container {
    width: 100%;
    height: 20px;
    font-size: 14px;
    color: red;
}

.fpp-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.fpp-info-container {
    margin-top: 10px;
    text-align: center;
}

.answer.textarea {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    border: solid 2px var(--session1);
    text-align: left;
    background-color: #fff;
    overflow: hidden;
}

.answer.textarea textarea {
    width: calc(100% - 20px);
    height: auto;
    border: none;
    padding: 10px;
}

.answer.textarea textarea:focus {
    outline: none;
}

.child-reasons-container,
.parent-reasons-container {
    margin-top: 20px;
}

.parent-reasons-container {
    margin-top: 0;
}

span.bold {
    font-weight: bold;
}

.action-points-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.super-chef-tips-container,
.goal-plan-container,
.welcome-back-container,
.time-to-watch-container {
    /*width: 100%;*/
    margin-bottom: 20px;
    border-radius: 7px;
    border: solid 2px var(--session1);
    padding: 10px;
}

.welcome-back-container,
.time-to-watch-container {
    text-align: center;
    padding: 30px 10px;
}

/* Video Section */
.video-container {
    padding-bottom: 20px;
}

/* FPP Section */
.fpp-textarea {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.fpp-textarea textarea {
    width: 100%;
    height: 100px;
    border: solid 2px var(--session1);
    border-radius: 7px;
    padding: 10px;
    font-size: 18px;
}

.fpp-textarea textarea:focus {
    outline: none;
}

.fpp-container-bottom {
    width: 100%;
    margin-top: 30px;
}

.answer.text-input {
    overflow: hidden;
    margin-bottom: 10px;
}

.answer.text-input input {
    width: 100%;
    height: 100%;
    font-size: 18px;
    padding-left: 10px;
}

.answer.text-input input:focus {
    outline: none;
}

.text-container {
    margin-top: 20px !important;
}

/* Sidebar - Archives, Additional Content */
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.3em;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
}

.btn:hover {
    cursor: pointer;
}

.btn:focus {
    outline: none;
}

.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn.sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    letter-spacing: 0.20rem;
    background-color: var(--button-primary);
    padding: 10px 20px;
    border-radius: 0 0 7px 7px;
    width: 125px;
    border: none;
    color: #fff;
    box-shadow: -1px 10px 29px 5px rgba(38, 50, 56, 0.43);
}

.btn.sidebar:hover {
    background-color: #e78431;
    cursor: pointer;
}

.sidebar-menu-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
}

.sidebar-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 600px;
    height: 100%;
    background-color: var(--header);
    display: flex;
    flex-direction: column;
    transform: translate(100%, 0);
    transition: all 0.5s ease-in-out;
    z-index: 101;
    box-shadow: -1px 18px 29px 8px rgba(38, 50, 56, 0.43);
}

.sidebar-container.active {
    transform: rotate(0deg);
}

.sidebar-container .btn.sidebar {
    position: absolute;
    top: 125px;
    left: -86px;
    transform: rotate(90deg);
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 0 20px;
}

.sidebar-wrapper {
    width: 100%;
    height: 100%;
}

.sidebar-links-container {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sidebar-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-left: 20px;
}

h4.sidebar-subtitle {
    font-family: 'Gabarito', sans-serif;
    font-size: 36px;
    margin-top: 0;
}

h6.links-title {
    font-family: 'Peralta', serif;
    font-size: 42px;
    margin-top: 0;
}

.sidebar-links li {
    font-size: 18px;
    line-height: 1.3em;
    margin-bottom: 10px;
}

.sidebar-links li.link-icon-container {
    margin-bottom: 5px;
}

.link-icon-container {
    width: 100%;
    display: inline-flex;
}

li.link-icon-container span {
    vertical-align: middle;
}

li.link-icon-container span svg {
    width: 20px;
    height: 20px;
    margin-top: 5px;
}

.sidebar-links li a {
    color: var(--text1);
    text-decoration: none;
}

.sidebar-links li a:hover {
    color: var(--session1);
}

/* Progress Link */
.sidebar-progress-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 1rem 0 1rem;
}

.super-chef-progress-link a {
    text-decoration: none;
}

.super-chef-progress-link a:hover {
    color: #a9429f;
}

.mb-20 {
    margin-bottom: 20px;
}

h1.med {
    font-size: 40px;
}

h1.med-2 {
    font-size: 28px;
}

.ml-2 {
    margin-left: 2px;
}

/* Progress Bar */
.progress-container {
    width: 100%;
    position: relative;
    padding-bottom: 20px;
}

.mobile-progress-container {
    display: none;
}

.progress-bar {
    width: calc(100% - 20px);
    height: 18px;
    margin: 0 auto;
    display: flex;
    background-color: #9ea7af;
    border: solid 1px #b0bec5;
    border-radius: 7px;
    position: relative;
}

.accent-text.progress-color {
    color: #d060ff;
}

.accent-text.blue {
    color: rgba(0, 163, 255, 1);
}

.accent-text.yellow {
    color: rgba(247, 213, 100, 1);
}

.accent-text.green {
    color: rgba(190, 228, 53, 1);
}

.accent-text.red {
    color: rgba(253, 119, 119, 1);
}

.accent-text.purple {
    color: rgba(182, 85, 172, 1);
}

.avatar-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0 10px;
}

.avatar-row {
    width: calc(100% - 40px);
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(50%, -50%);
    z-index: 10;
    transition: width 0.5s ease-in-out;
}

.progress-base {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 0 40px 0;
    border: solid 1px #c2d1d9;
    border-radius: 7px;
    background: rgb(239, 239, 239);
    box-shadow: 0 -2px 15px -6px rgba(0, 0, 0, 0.5) inset;
    -webkit-box-shadow: 0 -2px 15px -6px rgba(0, 0, 0, 0.5) inset;
    -moz-box-shadow: 0 -2px 15px -6px rgba(0, 0, 0, 0.5) inset;
}

.points-text-container {
    width: calc(100% - 40px);
    display: flex;
    justify-content: flex-end;
    margin: 10px auto 10px;
    font-size: 20px;
    color: #9ea7af;
}

.progress-bar-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 7px;
    box-shadow: 0 -2px 15px -6px rgba(0, 0, 0, 0.75) inset;
}


.content-inner.progress {
    width: calc(100% - 40px);
    padding: 10px 0;
}

.progress-badge-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.progress-badge {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.progress-badge-image-container {
    width: 100%;
    max-width: 600px;
    text-align: center;
}

.progress-badge-title {
    font-size: 28px;
    color: #fff;
    line-height: 1.3em;
    margin: 10px 0;
    padding: 8px 30px;
    background: rgba(109, 191, 120);
    border-radius: 7px;
}

.progress-badge-title.progress-color {
    background: #d060ff;
}

.progress-badge-title.two {
    background: rgba(138, 138, 138);
}

.progress-badge-title.three {
    background: rgba(183, 114, 113);
}

.progress-badge-title.four {
    background: rgba(195, 195, 195);
}

.progress-badge-title.five {
    background: rgba(233, 197, 71);
}

.progress-badge-title.six {
    background: rgba(160, 126, 240);
}

/* Review Section */
.content-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
}

.content-item .question {
    margin-bottom: 10px;
}

.content-item li {
    font-size: 18px;
    line-height: 1.3em;
    margin-bottom: 10px;
    margin-left: 35px;
    display: flex;
    align-items: center;
    gap: 7px;
}

.content-outer.summary {
    margin-bottom: 40px;
}

.mt-10 {
    margin-top: 10px;
}

/* Modal */
::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

#dialogModal {
    width: 100%;
    max-width: 600px;
    border: none;
    border-radius: 15px;
}

.dialog-modal-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.info-modal-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.modal-close-btn {
    position: absolute;
    right: 0;
    background-color: transparent;
    border: none;
}

.modal-close-btn:hover {
    cursor: pointer;
}

.modal-close-btn:focus {
    outline: none;
}

.info-modal-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.info-modal-btn {
    width: 100%;
    padding: 10px 0;
    color: #fff;
    background-color: var(--button-primary);
    border: none;
    font-size: 18px;
    border-radius: 7px;
}

.info-modal-btn:hover {
    cursor: pointer;
    background-color: #e78431;
}

.logo a:focus {
    outline: none;
}

.session-right-2-4 img,
.session-left-1-3 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 13px;
}

/* Temporary styles */
.sidebar-links li a.missing {
    color: red;
}

.sidebar-links li a.missing:hover {
    color: #de0202;
}

.not-found-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.session-title-container.mobile {
    display: none;
}

/*Session Dashboard*/
.session-boxes.mobile {
    display: none;
}

.session-boxes {
    display: none;
}

.session-boxes-container {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.session-item {
    width: 100%;
    max-width: 520px;
    margin: 0 20px 40px;
    height: 280px;
    border-radius: 25px;
    border: 5px solid;
    background-color: #fff;
    display: flex;
}

.plan-goal-container {
    display: flex;
    flex-direction: column;
}

.plan-goal.mobile {
    display: none;
}

.progress-title-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.progress-title-container h1 {
    margin-bottom: 8px;
}

.tips-title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tips-legend {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.skeleton-body {
    display: inline-flex;
    height: 1em;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
    border-radius: 7px;
}

.skeleton-body.large {
    max-width: 100%;
}

.skeleton-body.medium {
       width: 100%;
    max-width: calc(100% - 380px);
}

.skeleton-body.small {
       width: 100%;
    max-width: calc(100% - 200px);
}

.skeleton-body::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    animation: loading 1.2s infinite;
}

@keyframes loading {
    100% {
        left: 100%;
    }

}

.show-bonus-points-container {
    width: 100%;
}

.show-bonus-points-container ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
}

.points-row {
    margin-top: 10px;
}

.content-center {
    width: 100%;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}