/* .Tablet */
@media (max-width: 870px) {
    /* Header */
    .wrapper,
    .wrapper-2 {
        width: 100%;
        padding-bottom: 6%;
    }

    .header,
    .header-2 {
        height: 75px;
        margin-bottom: 68px;
        margin-top: 12px;
        padding-top: 16px;
    }

    .header-2 {
        margin-bottom: 88px;
    }

    .header-content {
        width: calc(100% - 32px);
    }

    .logo {
        width: 288px;
        margin: -26px auto 0;
    }

    /* Footer */
    footer {
        height: 51px;
    }

    .footer-container {
        width: 100%;
    }

    /* Login */
    .content-inner-login {
        width: calc(90% - 100px);
        padding: 48px 64px 28px;
    }

    .login-wrapper {
        width: 100%;
    }

    .login-parent,
    .login-child {
        width: 100%;
        margin: 0 2px 24px;
        border-radius: 15px 15px 15px 15px;
    }

    .login-child {
        margin-top: 24px;
    }

    .login-answer {
        width: calc(100% - 20px);
        height: 100%;
        min-height: 43px;
        margin: 0 10px 16px;
    }

    .login-answer input {
        width: calc(100% - 20px);
        height: 100%;
        min-height: 43px;
        font-size: 16px;
    }

    .login-answer.password button svg {
        width: 16px;
        height: 16px;
    }

    h6 {
        font-size: 30px;
        margin-top: 66px;
    }

    p.text-sm {
        font-size: 14px;
        line-height: 1.1em;
        margin-bottom: 8px;
    }

    p.question {
        font-size: 20px;
        margin-bottom: 16px;
    }

    /* Sessions */
    .content-outer {
        margin: 0 auto 32px;
    }

    .content-outer,
    #progress .content-outer {
        width: 96%;
        padding-bottom: 6%;
    }

    .content-avatar {
        width: 144px;
        height: 136px;
        margin-top: -70px;
        margin-left: 33px;
    }

    .content-title {
        width: 100%;
        max-width: fit-content;
        margin-left: 20px;
    }

    h1 {
        font-size: 28px;
        margin-top: 12px;
    }

    h1.med {
        font-size: 28px;
        margin-top: 12px;
    }

    h1.med-2 {
        font-size: 20px;
    }

    .content-inner {
        width: calc(90% - 100px);
        padding: 28px 50px;
    }

    .answer {
        width: 100%;
        /*height: 100%;*/
        min-height: 43px;
        margin-bottom: 6px;
    }

    .form-input-container {
        min-height: 43px;
        gap: 16px;
    }

    .checkbox-button-container {
        width: 43px;
        min-width: 43px;
        min-height: 43px;
    }

    .form-input-container label {
        font-size: 16px;
        line-height: 1em;
        padding-right: 10px;
    }

    .checkbox {
        width: 13px;
        height: 13px;
    }

    .btn-container {
        margin-top: 16px;
    }

    #progress.btn-container {
        margin: 0 0 24px;
    }

    .btn-primary {
        font-size: 20px;
        line-height: 1.1em;
        padding: 6px 24px
    }

    .btn-primary.logout {
        max-width: 170px;
    }

    .content-inner-thumb {
        width: 100%;
        max-width: 528px;
        padding: 0;
    }

    .content-inner-thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    p.goal-setting-label,
    p.tips-label,
    p.action-label,
    p.fpp-label,
    p.bonus-points-label,
    p.plan-goal-label,
    p.goal-reporting,
    p.super-power-label {
        font-size: 18px;
        line-height: 1.1em;
        margin-bottom: 8px;
    }

    .super-chef-tips-container,
    .goal-plan-container {
        width: unset;
        margin-bottom: 18px;
        padding: 10px;
    }

    .list li {
        font-size: 16px;
        line-height: 1.2em;
        margin-bottom: 12px;
    }

    .form-error-container {
        height: 18px;
        font-size: 12px;
    }

    p.info-text {
        font-size: 16px;
        line-height: 1.1em;
        margin-bottom: 8px;
    }

    h1.med {
        font-size: 26px;
    }

    p.fpp-label.title,
    p.not-found {
        font-size: 18px;
        line-height: 1.1em;
    }

    .fpp-container-bottom {
        margin-top: 24px;
    }

    .mt-10 {
        margin-top: 8px;
    }

    .list.plan-goal {
        margin-left: 1.2rem;
    }

    .child-reasons-container {
        margin-top: 16px;
    }

    .email-container {
        padding-top: 8px;
    }

    .video-container {
        padding-bottom: 16px;
    }

    #video-player {
        width: 100% !important;
        height: 400px !important;
        max-width: 550px !important;
        margin: 0 auto !important;
    }

    /* Dashboard */
    .session-boxes {
        display: none;
    }

    .session-boxes.mobile {
        width: 94%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    .session-boxes .session-link {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .session-single {
        width: 100%;
        max-width: 432px;
        height: 224px;
        margin: unset;
        display: flex;
        flex-direction: column;
    }

    .session-top {
        height: 172px;
        display: flex;
        justify-content: space-between;
    }

    .session-left-1-3,
    .session-right-2-4 {
        /*width: 196px;*/
        height: 192px;
        float: unset;
        margin-top: -22px;
    }

    .session-right-1-3,
    .session-left-2-4 {
        width: 236px;
    }

    .session-right-2-4 img,
    .session-left-1-3 img {
        margin-top: 11px;
        width: auto;
        object-fit: unset;
    }

    .session-bottom {
        width: calc(100% - 16px);
        height: 52px;
        padding-left: 16px;
        padding-top: 8px;
    }

    span.locked,
    span.start {
        line-height: 1.6em;
        letter-spacing: .04em;
        font-size: 28px;
    }

    .session-boxes-container {
        display: none;
    }

    /* Sidebar */
    .sidebar-container {
        width: 85vw;
    }

    .sidebar-backdrop.active {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease-in-out;
        z-index: 100;
    }

    .btn.sidebar {
        font-size: 1.1rem;
        letter-spacing: 0.18rem;
        padding: 8px 16px;
        width: 100px;
    }

    .sidebar-container .btn.sidebar {
        top: 100px;
        left: -69.5px;
    }

    .sidebar-content {
        padding: 0 0 16px;
    }

    .super-chef-progress-link a {
        font-size: 15px;
    }

    h4.sidebar-subtitle {
        font-size: 28px;
    }

    .sidebar-links-container {
        gap: 8px;
    }

    .sidebar-links {
        margin-left: 16px;
    }

    h6.links-title {
        font-size: 33px;
    }

    .sidebar-links li {
        font-size: 16px;
        line-height: 1.2em;
        margin-bottom: 10px;
    }

    /* Review page */
    .content-outer.summary {
        margin-bottom: 32px;
    }

    .content-item {
        margin-bottom: 16px;
    }

    .content-item .question {
        margin-bottom: 8px;
    }

    .content-item li {
        font-size: 16px;
        line-height: 1.1em;
        margin-left: 30px;
    }

    .content-item svg {
        font-size: 1rem !important;
    }

    /* Info Modal */
    .dialog-modal-container {
        padding: 8px;
    }

    #dialogModal {
        width: 90%;
        border: none;
        border-radius: 15px;
    }

    .modal-close-btn svg {
        width: 20px;
        height: 20px;
    }

    .info-modal-title-container h1 {
        margin-top: 0;
    }

    .info-modal-btn {
        font-size: 16px;
    }

    .info-modal-content-container p {
        font-size: 15px;
        line-height: 1.1em;
    }

    /* Progress page */
    .content-inner.progress {
        width: calc(100% - 32px);
        padding: 28px 0;
    }

    .progress-container {
        display: none;
    }

    .mobile-progress-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .points-text-container.mobile {
        justify-content: center;
    }

    .progress-badge-image-container {
        width: 100%;
        max-width: 280px;
        display: flex;
        justify-content: center;
    }

    .progress-badge-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .progress-title-container h1 {
        font-size: 28px;
    }

    .skeleton-body.medium {
        max-width: calc(100% - 200px);
    }

    p.question.sub {
        font-size: 19px;
        margin-bottom: 8px;
    }

    .progress-badge-title {
        font-size: 22px;
    }

    .list {
        margin-bottom: 8px;
    }

    /* Login page */
    .login-container {
        min-height: 75vh;
    }

}

/* Mobile */
@media (max-width: 540px) {
    .session-title-container {
        display: none;
    }

    .progress-title-container h1 {
        font-size: 20px;
    }

    .content-title.mobile h1 {
        /*font-size: 20px;*/
        font-size: clamp(20px, 3vw, 28px);
        margin-top: 18px;
    }

    .session-title-container.mobile {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .content-avatar.mobile {
        width: 130px;
        height: 123px;
        margin-top: -60px;
        margin-left: -8px;
    }

    .content-avatar.mobile.small {
        margin-left: 12px;
    }

    .content-title.mobile {
        width: calc(100% - 32px);
        margin-left: 0;
    }

    .content-title.mobile.fpp h1 {
        font-size: 17px;
    }

    .content-title.mobile.bonus h1 {
           font-size: clamp(14px, 3vw, 20px);
       }

   .form-input-container label {
        font-size: clamp(14px, 3vw, 16px);
    }

    p.question {
        font-size: 18px;
        margin-bottom: 12px;
    }

    p.goal-setting-label,
    p.tips-label,
    p.action-label,
    p.fpp-label,
    p.bonus-points-label,
    p.plan-goal-label,
    p.goal-reporting,
    p.super-power-label {
        font-size: 16px;
        line-height: 1em;
        margin-bottom: 8px;
    }

    .btn-container {
        margin-top: 12px;
    }

    #progress.btn-container {
        margin: 0 0 20px;
    }

    .answer {
        display: inline-flex;
        width: 100%;
        height: auto;
        margin-bottom: 6px;
    }

    .answer label {
        line-height: 1.1em;
        padding: 5px;
    }

    .form-input-container {
        height: auto;
        gap: 8px;
    }

    .content-inner {
        width: calc(100% - 100px);
        padding: 28px 25px;
    }

    .content-inner.video {
        width: calc(100% - 90px);
        padding: 28px 25px;
    }

    .plan-goal-container {
        display: none;
    }

    .plan-goal.mobile {
        display: flex;
        flex-direction: column;
    }

    .content-avatar.mobile.small {
        width: 130px;
        height: 123px;
        margin-top: -60px;
        margin-left: 18px;
    }

    h6.links-title {
        font-size: 24px;
    }

    .tips-legend {
        gap: 8px;
        font-size: 14px;
    }

    p.question.sub {
        font-size: 18px;
    }

    .list {
        padding-left: 17px;
        margin-bottom: 0;
    }

    .list.plan-goal {
        margin-left: 0.8rem;
    }
}